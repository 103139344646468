import {
	getLocalStorageReac,
	getSessionStorageReac,
	setLocalStorageReac,
	setSessionStorageReac,
} from "@/assets/js/helpers";
import { jwtDecode } from "jwt-decode";
import { useToastStore } from "./toast";
import type { TUserData } from "@/assets/js/types";
import api from "@/api";
import { useRouter } from "vue-router";
import { useCandidateStore } from "./candidate";
import { baseURL } from "@/api/config";

export const useTokenStore = defineStore("tokenStore", () => {
	const roles = [
		{ rol_id: 1, rol_name: "Candidate" },
		{ rol_id: 2, rol_name: "Agency Owner" },
		{ rol_id: 3, rol_name: "Agency Admin" },
		{ rol_id: 4, rol_name: "Agency Recruiter" },
		{ rol_id: 5, rol_name: "Agency Assistant" },
		{ rol_id: 6, rol_name: "super-admin" },
		{ rol_id: 7, rol_name: "Collaborator" },
		{ rol_id: 8, rol_name: "managed-user" },
		{ rol_id: 9, rol_name: "admin" },
	];
	const router = useRouter();
	const userToken = ref("");
	const userData = ref<TUserData | null>();
	const tokenRefreshTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
	const isBusyRenewalToken = ref(false);

	const getTokenString = computed(() => userToken.value);
	const isLoggedIn = computed(() => !!userToken.value);
	const isUserAdmin = computed(
		() =>
			userData.value?.rol_name === "admin" ||
			userData.value?.rol_name === "super-admin" ||
			userData.value?.rol_id === 9 ||
			userData.value?.rol_id === 6,
	);
	const userPermissions = computed<string[]>(
		() => userData.value?.permissions || [],
	);

	// rol_name:
	// 	| "admin"
	// 	| "candidate"
	// 	| "b2b"
	// 	| "agency"
	// 	| "super-admin"
	// 	| "agency-owner"
	// 	| "agency-admin"
	// 	| "agency-worker"
	// 	| "agency-intern"
	// 	| "b2b-owner"
	// 	| "managed-user";

	const isUserCompany = computed(
		() =>
			// userData.value?.rol_name === "agency" ||
			// userData.value?.rol_name === "agency-owner"||
			userData.value?.rol_id === 2,
	);

	const isUserPresentCompany = computed(
		() =>
			// userData.value?.rol_name === "agency" ||
			// userData.value?.rol_name === "agency-owner" ||
			// userData.value?.rol_name === "agency-admin" ||
			// userData.value?.rol_name === "agency-worker" ||
			// userData.value?.rol_name === "agency-intern" ||
			userData.value?.rol_id === 2 ||
			userData.value?.rol_id === 3 ||
			userData.value?.rol_id === 4 ||
			userData.value?.rol_id === 5,
	);

	const isUserB2B = computed(
		() =>
			// userData.value.rol_name === "b2b" ||
			// userData.value.rol_name === "b2b-owner" ||
			// userData.value.rol_name === "b2b-worker",
			userData.value?.rol_id === 7,
	);

	const isCandidate = computed(() => {
		return userData.value?.rol_id === 1 || userData.value?.rol_id === 8;
	});

	const userImage = computed(() => {
		return getImageUrl(userData.value?.img_id);
	});
	function getImageUrl(imageId?: number | string): string | null {
		if (imageId) {
			return `${baseURL}/users/images-get/${imageId}`;
		}
		return null;
	}
	function updateUserImageId(id: number) {
		// For extenal avatar change
		if (userData.value) {
			userData.value.img_id = id;
		} else {
			console.warn(":: User data doesn't exits");
		}
	}
	function updateUserName(name: string) {
		// For extenal name change
		if (userData.value) {
			userData.value.usr_name = name;
		} else {
			console.warn(":: User data doesn't exits");
		}
	}

	function saveToken(token: string, isRemember = false) {
		userToken.value = token;
		userData.value = token ? jwtDecode(token) : null;
		// console.log(jwtDecode(token));
		console.log(userData.value);
		if (token) {
			if (isRemember) {
				setLocalStorageReac("user-token", token);
			} else {
				setSessionStorageReac("user-token", token);
			}
		} else {
			const sesToken = getSessionStorageReac("user-token");
			const locToken = getLocalStorageReac("user-token");
			sesToken.value = null;
			locToken.value = null;
		}
	}
	async function loadSessionToken() {
		const sesToken =
			(getSessionStorageReac("user-token").value as string) || "";
		const locToken = (getLocalStorageReac("user-token").value as string) || "";

		const loadSession = async (token: string) => {
			if (typeof token === "string") {
				saveToken(token);
				if (!tokenRefreshTimeout.value) {
					await setupTokenRenewal(true);
				}
			} else {
				console.error("Invalid token format", token);
			}
		};

		if (sesToken) {
			await loadSession(sesToken);
		} else if (locToken) {
			await loadSession(locToken);
		}
	}

	async function setupTokenRenewal(isRefreshToken = false, debug = true) {
		if (isBusyRenewalToken.value) {
			// console.warn(":: Busy token, exiting");
			return;
		}

		isBusyRenewalToken.value = true;
		tokenRefreshTimeout.value && clearTimeout(tokenRefreshTimeout.value);
		tokenRefreshTimeout.value = null;

		if (isRefreshToken) {
			await renewToken();
		}

		const TIME_TO_REFRESH = 15 * 60 * 1000;
		if (debug) {
			console.log(
				`:: Setup token refresh - ${TIME_TO_REFRESH / 60 / 1000}min`,
				isRefreshToken,
			);
		}
		tokenRefreshTimeout.value = setTimeout(() => {
			void setupTokenRenewal(true);
		}, TIME_TO_REFRESH);
		isBusyRenewalToken.value = false;
		return;
	}
	function cancelRenewToken() {
		if (tokenRefreshTimeout.value) {
			clearTimeout(tokenRefreshTimeout.value);
			tokenRefreshTimeout.value = null;
		}
	}
	async function renewToken() {
		if (!userToken.value && tokenRefreshTimeout.value) {
			return;
		}
		// console.log("-----ppp");
		try {
			const res = await api.authRenewSession();
			const token = res.data.data.data;
			console.log(":: Token refreshed");
			saveToken(token);
		} catch {
			// ignored
		}
	}

	async function setupLogin(
		payload: Parameters<typeof api.authLogin>[0],
		isRemember = false,
	) {
		try {
			const res = await api.authLogin(payload);
			saveToken(res.data.data.data, isRemember);
			return true;
		} catch (err: any) {
			console.warn(err.message);
			return false;
		}
	}
	async function logoutApi(
		showMsg = false,
		shouldRedirect = true,
	): Promise<boolean> {
		const checkRedirectUser = () => {
			if (shouldRedirect) {
				void router.replace("/");
			}
		};
		if (!userToken.value) {
			console.warn(":: User already logged out");
			// useToastStore().openToastWarning("User already logged out");
			checkRedirectUser();
			return false;
		}
		try {
			await api.authLogout();
			useCandidateStore().clearUserForm();
			cancelRenewToken();

			if (showMsg) {
				useToastStore().openToastInfo("User logged out");
			}

			checkRedirectUser();

			return true;
		} catch {
			return false;
		} finally {
			saveToken("", false);
		}
	}

	/**
	 * This function is used to check if the current user has specific permission
	 */
	function can(...keys: string[]) {
		return keys.every((key) => userPermissions.value.includes(key));
	}

	return {
		getTokenString,
		isLoggedIn,
		isUserAdmin,
		isUserCompany,
		isUserPresentCompany,
		isUserB2B,
		isCandidate,
		userData,
		userImage,
		can,
		setupLogin,
		saveToken,
		loadSessionToken,
		setupTokenRenewal,
		logoutApi,
		getImageUrl,
		updateUserImageId,
		updateUserName,
		renewToken,
		roles,
	};
});
