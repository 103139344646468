import { useRoute } from "vue-router";
import { useTokenStore } from "./token";
export const useMessageStore = defineStore("messages", () => {
	// private - it doesn't work if it's in computed
	const currentRoute = useRoute();

	const chat_room = computed(() => {
		if (currentRoute.name === "ChatApplication") {
			return Number(currentRoute.params.id);
		}
		return null;
	});

	const globalMsg = ref<any>({});

	// decide if cb should have return type
	function triggerMessageGet(app_id: number, cb: () => void) {
		if (!chat_room.value) {
			return;
		}

		if (app_id === chat_room.value) {
			cb();
		}
	}

	function triggerMessageGetAll(app_id: number, message: any, cb: () => void) {
		console.log(message?.room);
		if (
			message?.type === "sendNotification" ||
			(message?.type === "new-message" &&
				currentRoute.name !== "ChatApplication" &&
				!message.room)
		) {
			globalMsg.value = message;
		}
		if (message?.message === "refresh-jwt") {
			console.log(message);
			useTokenStore().renewToken();
		}
		if (app_id === chat_room.value) {
			cb();
		}
	}

	// SEND MSG TO
	type TMsgTo = {
		usr_id: number;
		usr_name: string;
		job_id?: number;
	};

	const msgTo = ref<TMsgTo>(null);

	function setSendMsgTo(e: TMsgTo) {
		msgTo.value = e;
	}

	return {
		chat_room,
		triggerMessageGet,
		msgTo,
		setSendMsgTo,
		triggerMessageGetAll,
		globalMsg,
	};
});
