<script setup lang="ts">
import api from "@/api";
import { useRoute } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";
import { useTokenStore } from "@/store/token";
import { useToastStore } from "@/store/toast";
import type { TFileObj } from "@/assets/js/types";

import {
	openModalRejectFile,
	openConfirmModal,
	openManageCollaborators,
	openManageEvent,
} from "@/components/modals/modalDefinitions";
import type { CWebsocket } from "@/assets/js/websocketHelper";
import { useSocketStore } from "@/store/socket";
import { useMessageStore } from "@/store/message";
import { downloadFile } from "@/assets/js/helpers";

definePage({
	name: "ChatApplication",
	path: "/manage/chat-page/:id",
});

type TRoom = {
	usr_id: number | null;
	usr_name: string;
	img_id: number | null;
	chat_type: string;
	unread: number;
};

const route = useRoute();
const router = useRouter();
const tokenStore = useTokenStore();
const toastStore = useToastStore();
const showComponent = ref<"chat" | "documents">("chat");
const messageStore = useMessageStore();

const data = ref<any>();
const rooms = ref<TRoom[]>([]);
const selectedRoom = ref<TRoom>(null);
const messages = ref<any[]>([]);
const isInfiniteScroll = ref(true);

const fList = ref<TFileObj[]>([]);

interface IStatuses {
	aps_id: number;
	aps_name: string;
	aps_public: boolean;
}
const statuses = ref<IStatuses[]>([]);

const app_id = computed(() => {
	return Number(route.params.id) || null;
});

const holderOrHavePrivilage = computed(() => {
	// canAddEvent
	// tokenStore.can('evt-add') || data.usr_id_responsible === tokenStore.userData?.usr_id
	// canAddCollaborators and canAddEvent have the same condition, but will be changed in the future
	return (
		data.value.usr_id_responsible === tokenStore.userData?.usr_id ||
		tokenStore.isUserCompany ||
		tokenStore.userData?.rol_id === 3 || // "agency-admin"
		tokenStore.userData?.rol_id === 4 // "agency-worker" according to the latest
	);
});

const canAddCollaborators = computed(() => {
	// tokenStore.can('ain-add')
	// canAddCollaborators and canAddEvent have the same condition, but will be changed in the future
	return (
		data.value.usr_id_responsible === tokenStore.userData?.usr_id ||
		tokenStore.isUserCompany ||
		tokenStore.userData?.rol_id === 3 || // "agency-admin"
		tokenStore.userData?.rol_id === 4 // "agency-worker" according to the latest
	);
});

const socket = useSocketStore();
socket.setOptions({
	cb: {
		onMessageData: (
			ws: CWebsocket,
			evt: WebSocketEventMap["message"],
			message: any,
		) => {
			// console.log("*******************************++");
			console.log(message);
			messageStore.triggerMessageGetAll(app_id.value, message, async () => {
				if (message?.type === "new-message") {
					const index = rooms.value.findIndex(
						(obj) => obj.chat_type === message?.room,
					);
					console.log("index", index);

					if (selectedRoom.value?.chat_type === message?.room || index === -1) {
						await getMessages(false, "new");
					} else if ((index || index === 0) && index !== -1) {
						rooms.value[index].unread = 1;
					}
					getMessages(false, "new");
				}
				if (message?.type === "new-attachemnt") {
					applicationsAttachmentsGet();
				}
			});
		},
	},
});
// socket.initSocket();

// functionality
function formatDate(date: string) {
	if (date) {
		return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
	}
}
async function getApplicationStatuses() {
	try {
		// @ts-expect-error
		const res = await api.getApplicationStatuses();
		statuses.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

async function changeStatus(e: any) {
	const params = {
		app_id: data.value.app_id,
		aps_id: e.aps_id,
	};
	try {
		const res = await api.setApplicationStatus(params);
		console.log(res);
		toastStore.openToastSuccess("Changed status successfully.");
	} catch (e: any) {
		console.log(e.response.data.data);
		if (e.response?.data?.data) {
			toastStore.openToastError(e.response.data.data);
		} else {
			toastStore.openToastError(e.response.data.data);
		}
	}
	await getApplication();
}

async function getRooms() {
	const params = {
		app_id: route.params.id,
		offset: messages.value.length,
		limit: 20,
	};
	try {
		const res = await api.getRooms(params);
		// console.log(res.data);
		rooms.value = res.data.data;

		// messages.value.unshift(...res.data.data.reverse());
		// isInfiniteScroll.value = false;
	} catch (e) {
		console.log(e);
	}
}

async function getMessages(reset = false, view = "old") {
	isInfiniteScroll.value = false;
	// console.log("dobavi poruke");
	if (!selectedRoom.value) {
		return;
	}
	if (reset) {
		messages.value = [];
		isInfiniteScroll.value = true;
	}
	type TParams = {
		app_id: number;
		// msg_id_last_seen: number | null;
		last_msg_id: number | null;
		usr_id_context: number | null; // b2b-b2b or b2b-aplicant
		room: string;
		view: "new" | "old" | string;
	};
	const params: TParams = {
		app_id: Number(route.params.id),
		last_msg_id:
			view === "old"
				? messages.value.length <= 0
					? null
					: messages.value.at(-1).msg_id
				: messages.value.length <= 0
					? null
					: messages.value.at(0).msg_id,
		usr_id_context: selectedRoom.value.usr_id,
		room: selectedRoom.value.chat_type,
		view: view,
	};

	// console.log(params, "pozovi api ili sockete");
	// messages.value.push(...res.data.data);
	try {
		const res = await api.getMessages(params);
		// console.log(res.data.data);
		// messages.value = res.data.data;
		// messages.value.unshift(...res.data.data.reverse());
		if (view === "old") {
			messages.value.push(...res.data.data.reverse());
		} else if (view === "new") {
			messages.value.unshift(...res.data.data);
			await setSeen(res.data.data[0].msg_id);
		}

		if (res.data.data.length > 0) {
			isInfiniteScroll.value = true;
		}
	} catch (e) {
		console.log(e);
	}
}

async function getApplication() {
	try {
		// const res = await api.getApplication({ app_id: route.params.id });
		// // console.log(res.data);
		let res: any = null;
		res = await (tokenStore.isUserB2B
			? api.invitesGetSingle({ app_id: route.params.id })
			: api.getApplication({ app_id: route.params.id }));
		data.value = res?.data?.data?.length > 0 ? res.data.data[0] : null;
	} catch (e) {
		console.log(e);
	}
}

async function applicationsAttachmentsGet() {
	try {
		const res = await api.applicationsAttachmentsGet({
			app_id: route.params.id,
		});
		// console.log(res.data.data);
		fList.value = res.data.data;
	} catch (e: any) {
		console.log(e);
	}
}

function approvedFile(e: TFileObj) {
	console.log("approvedFile");
	console.log(e);
	openConfirmModal(
		{ name: e.file_title, data: e },
		confirmApproved,
		"Approve this file ?",
	);
}

function rejectFile(e: TFileObj) {
	console.log("rejectFile");
	console.log(e);
	openModalRejectFile(e, confirm);
}

// function open(e: TFileObj) {
// 	console.log(e);
// 	console.log("pozvati komponentu za prikaz fajlova");
// }
async function open(file: { file_title: string; file_id: number }) {
	if (!file) {
		return;
	}
	// https://204b123.mars-t.mars-hosting.com/api_v1/users/images-get/

	try {
		const res = await api.appFilesGetSingle({
			file_id: file.file_id,
			app_id: Number(route.params.id),
		});
		console.log(res.data);
		downloadFile(res.data, file.file_title);
	} catch (e) {
		console.log(e.response.data);
		// download(e.response.data, file.file_title);
	}
}

async function confirmApproved(e: TFileObj) {
	console.log("pozovi api odbijanje fajlova 'Reject file'");
	console.log(e);
	// emit("close-modal");
	const params = {
		att_status: "APPROVED",
		att_id: e.att_id,
	};
	try {
		const res = await api.applicationsReview(params);
		console.log(res.data);
		toastStore.openToastSuccess("You have successfully approved the file.");
	} catch (e) {
		console.log(e);
		toastStore.openToastError("Error");
	}

	confirm();
}

function getCvApplication() {
	void router.push({
		name: "CandidateData",
		params: { id: route.params.id },
	});
}

function manageCollaborators() {
	console.log("otvori modal");
	openManageCollaborators(
		{ id: route.params.id, data: { ...data.value } },
		onManageCollaborators,
	);
}
function onManageCollaborators() {
	console.log("potvrdi");
}

async function selectMessages(e: TRoom) {
	console.log("set new chat msg", e);
	selectedRoom.value = e;
	await getMessages(true);
	if (e.unread === 1 && messages.value.length > 0) {
		console.log(messages.value[0].msg_id);
		await setSeen(messages.value[0].msg_id);
	}
}

async function send(e: { msg: string; file: number; reply: number | null }) {
	// console.log(e);
	// console.log("ovde posalji poruku");
	type TParams = {
		app_id: any;
		msg_content: string;
		usr_id_to?: number | null;
		file_id?: number; //  any[];
		room?: string;
		msg_id_parent?: number;
		type: string;
	};
	const params: TParams = {
		app_id: app_id.value,
		msg_content: e.msg,
		type: "sendMessageApplication",
	}; //new FormData();
	// params.append("app_id", route.params.id);
	// params.append("msg_content", e.msg);

	// if (selectedRoom.value.chat_type) {
	// params.usr_id_to = selectedRoom.value.usr_id;
	params.room = selectedRoom.value.chat_type;
	// } else {
	// params.room = selectedRoom.value.chat_type;
	params.usr_id_to = selectedRoom.value.usr_id;
	// }

	if (e.reply) {
		params.msg_id_parent = e.reply;
	}

	if (e.file) {
		params.file_id = e.file;
	}
	// if (e.file.length > 0) {
	// 	for (let i = 0; i < e.file.length; i++) {
	// 		params.append("file", e.file[i]);
	// 	}
	// }

	socket.sendMessage(JSON.stringify(params)); //await api.addMessage(params);
}

async function setSeen(id: number) {
	try {
		const res = await api.appMessagesSeen({ msg_id: id });
		if (res.data?.res === "OK") {
			setLocallySeen();
		}
	} catch (e) {
		console.log(e);
	}
}

function setLocallySeen() {
	const index = rooms.value.findIndex(
		(obj) =>
			obj.usr_id === selectedRoom.value?.usr_id &&
			obj.chat_type === selectedRoom.value?.chat_type,
	);
	if (index || index === 0) rooms.value[index].unread = 0;
}

// watch([search], async () => {
// 	// filter
// });
function openModal() {
	openManageEvent({ name: "add", app_id: app_id.value }, "", "add");
}

onMounted(async () => {
	await getApplication();
	await getRooms();
	// await getMessages();
	await getApplicationStatuses();
	await applicationsAttachmentsGet();
});

// function sendMessage() {
// 	const message = JSON.stringify({
// 		type: "sendMessageApplication",
// 		msg_content:
// 			"Hello sir would you like to engage in a heated argument about cats?",
// 		app_id: 696,
// 		room: "applicant",
// 		usr_id_to: 4509,
// 	});

// 	// app_id: 696,
// 	// 	room: "applicant",
// 	// 	usr_id_to: 4509,

// 	// app_id: 52,
// 	// 	room: "agency",
// 	// 	usr_id_to: 123,

// 	socket.sendMessage(message);
// }
</script>

<template lang="pug">
.page.chat
	//- button(@click="sendMessage") Send Message
	.main
		.up(v-if="data")
			.wrapp-title
				.line
				.w-t(@click="router.back()")
					.arrow-left
					.title.ml-15 {{ data?.usr_name }}
			.up-data.first
				.wrapp-data
					label.m3px {{ $t("app.companies.dateApplied") }}
					.split
						.date {{ formatDate(data.app_date) }}
				.wrapp-data(v-if="holderOrHavePrivilage || tokenStore.can('app-edit')")
					label {{ $t("app.companies.status") }}
					.split
						select-button(
							:status="data.aps_id ? data.aps_id : 'Created'",
							:statusName="data.aps_name ? data.aps_name : 'Created'",
							:list="statuses",
							label="aps_name",
							@change="changeStatus"
						)
			.up-data
				.split
					.wrapp-data(v-if="data.aps_id === 'SELE'")
						button(@click="getCvApplication")
							span {{ $t("app.myapplications.viewCV") }}
				.split
					.wrapp-data(v-if="canAddCollaborators")
						button(@click="manageCollaborators")
							span {{ $t("app.myapplications.manageCollaborators") }}
				.split
					.wrapp-data(v-if="holderOrHavePrivilage")
						button(@click="openModal")
							span {{ $t("calendar.addEvent") }}
		.wrapp-content
			wrapp-messages(
				:messages="messages",
				:isInfiniteScroll="isInfiniteScroll",
				:room="selectedRoom",
				:disableSend="data?.aps_id === 'RJCT'",
				@scroll-more="getMessages",
				@send="send"
			)
			.wrapp-file-list
				.select-component
					.wrapp-side
						.line-side
							.fill(:class="{ active: showComponent === 'chat' }")
						.title-side(
							:class="{ active: showComponent === 'chat' }",
							@click="showComponent = 'chat'"
						) {{ $t("app.messages.chat") }}
					.wrapp-side
						.line-side
							.fill(:class="{ active: showComponent === 'documents' }")
						.title-side(
							:class="{ active: showComponent === 'documents' }",
							@click="showComponent = 'documents'"
						) {{ $t("app.messages.documents") }}

				file-list(
					v-if="showComponent === 'documents'",
					:fileList="fList",
					:title="$t('app.file.candidateCV')",
					:showStatus="true",
					:showAction="holderOrHavePrivilage",
					:showRemove="false",
					@reject="rejectFile",
					@approved="approvedFile",
					@open="open"
				)
				wrapp-rooms(
					v-if="showComponent === 'chat'",
					:title="$t('app.messages.conversations')",
					:rooms="rooms",
					@select="selectMessages"
				)
				//- :rooms="[]"
</template>

<style lang="scss" scoped>
.chat {
	// padding: 20px;
	// height: 100%;
	// width: 100%;
	// font-family: "Open Sans", sans-serif;
	// box-sizing: border-box;
	// width: 100%;
	// max-width: 1224px;
	// border-radius: 10px;
	// border: $border;
	// background: $background-color;
	// padding: 0 30px 30px;
	// margin-bottom: 14px;
	// min-height: 100%;
	// height: fit-content;
	// display: flex;
	// flex-wrap: wrap;
	// justify-content: space-between;

	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	// box-sizing: border-box;

	.main {
		width: 100%;
		box-sizing: border-box;
		// max-width: 784px;

		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
			align-items: center;
		}

		.wrapp-title {
			align-self: flex-start;
			width: fit-content;
			margin-bottom: 25px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				// margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.w-t {
				display: flex;
				align-items: center;
				margin-top: 20px;
			}
			.w-t:hover {
				filter: opacity(0.7);
				cursor: pointer;
			}
			.arrow-left {
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-bottom: 12px solid transparent;
				border-right: 12px solid $app-accent-color1;
				display: inline-block;
				cursor: pointer;
			}
			.title {
				margin-left: 15px;
				display: inline;
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}
		.up-data {
			margin-bottom: 25px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.wrapp-data {
				margin-top: 15px;
				display: flex;
				align-items: center;
			}
			.m3px {
				margin-top: 3px;
				display: inline-block;
			}
			.split {
				margin-left: 10px;
			}
			.date {
				// font-family: Archivo, sans-serif;
				font-size: 13px;
				font-weight: 600;
				line-height: 15px;
				letter-spacing: 0;
				text-align: left;
			}
		}
		.first {
			// justify-self: flex-start;
			width: 370px;
			margin-left: 30px;
			margin-right: auto;
		}

		// main data
		.wrapp-content {
			display: flex;
			// justify-content: space-between;
			width: 100%;
			// messages
			.wrapp-messages {
				width: 65%;
				box-sizing: border-box;
			}

			// right side menu
			.wrapp-file-list {
				box-sizing: border-box;
				width: calc(35% - 15px);
				margin-left: 15px;
				padding: 0 20px;
				border: $border;
				border-radius: 10px;
				overflow: auto;

				.select-component {
					display: flex;
					justify-content: space-around;
					margin-bottom: 59px;
					.wrapp-side {
						.line-side {
							// height: 10px;
							height: 5px;
							border-radius: 0 0 20px 20px;
							overflow: hidden;
							filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.1));
						}
						.fill {
							height: 100%;
							width: 0;
							background-color: $app-accent-color1;
						}
						.fill.active {
							width: 100%;
							animation-name: line;
							animation-duration: 0.5s;
						}
						.title-side {
							margin-top: 16px;
							font-family: "Open Sans", sans-serif;
							font-size: 18px;
							font-weight: 700;
							line-height: 24.51px;
							text-align: left;
							cursor: pointer;
						}
						.title-side.active {
							color: $app-accent-color1;
						}
					}
				}

				.file-list {
					width: 100%;
					// max-width: 100%;
					// flex-wrap: wrap;
				}
			}
		}
	}
}

@keyframes line {
	from {
		width: 0%;
	}
	to {
		width: 100%;
	}
}
</style>
