import _definePage_default_0 from '/home/user/Documents/projects/imjob-web/src/pages/index/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/user/Documents/projects/imjob-web/src/pages/index/about.vue?definePage&vue'
import _definePage_default_3 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/chat-bot-page.vue?definePage&vue'
import _definePage_default_4 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/chat-main.vue?definePage&vue'
import _definePage_default_5 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/chat-application.vue?definePage&vue'
import _definePage_default_6 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/job-preview.vue?definePage&vue'
import _definePage_default_7 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/public-profile.vue?definePage&vue'
import _definePage_default_8 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/candidates.vue?definePage&vue'
import _definePage_default_9 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/community.vue?definePage&vue'
import _definePage_default_10 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/company-candidates.vue?definePage&vue'
import _definePage_default_11 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/contact.vue?definePage&vue'
import _definePage_default_12 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/cv.vue?definePage&vue'
import _definePage_default_13 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/employers.vue?definePage&vue'
import _definePage_default_14 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/feedback.vue?definePage&vue'
import _definePage_default_15 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/invitations-list.vue?definePage&vue'
import _definePage_default_16 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/job-listing.vue?definePage&vue'
import _definePage_default_17 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/job-saved.vue?definePage&vue'
import _definePage_default_18 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/my-applications.vue?definePage&vue'
import _definePage_default_19 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/notifications.vue?definePage&vue'
import _definePage_default_20 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/posts.vue?definePage&vue'
import _definePage_default_21 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/basic.vue?definePage&vue'
import _definePage_default_22 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/computer-skills.vue?definePage&vue'
import _definePage_default_23 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/education.vue?definePage&vue'
import _definePage_default_24 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/experience.vue?definePage&vue'
import _definePage_default_25 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/hobby.vue?definePage&vue'
import _definePage_default_26 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/languages.vue?definePage&vue'
import _definePage_default_27 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/portfolio.vue?definePage&vue'
import _definePage_default_28 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/skills.vue?definePage&vue'
import _definePage_default_29 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile.vue?definePage&vue'
import _definePage_default_30 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/reports.vue?definePage&vue'
import _definePage_default_31 from '/home/user/Documents/projects/imjob-web/src/pages/index/manage/user-calendar.vue?definePage&vue'
import _definePage_default_32 from '/home/user/Documents/projects/imjob-web/src/pages/index/settings/index/aboutme.vue?definePage&vue'
import _definePage_default_33 from '/home/user/Documents/projects/imjob-web/src/pages/index/settings/index/information.vue?definePage&vue'
import _definePage_default_34 from '/home/user/Documents/projects/imjob-web/src/pages/index/settings/index/social.vue?definePage&vue'
import _definePage_default_35 from '/home/user/Documents/projects/imjob-web/src/pages/index/settings/index.vue?definePage&vue'
import _definePage_default_36 from '/home/user/Documents/projects/imjob-web/src/pages/index/test.vue?definePage&vue'
import _definePage_default_37 from '/home/user/Documents/projects/imjob-web/src/pages/[...path].vue?definePage&vue'
import _definePage_default_38 from '/home/user/Documents/projects/imjob-web/src/pages/admin/company-information.vue?definePage&vue'
import _definePage_default_39 from '/home/user/Documents/projects/imjob-web/src/pages/admin/reported.vue?definePage&vue'
import _definePage_default_40 from '/home/user/Documents/projects/imjob-web/src/pages/admin/admin.vue?definePage&vue'
import _definePage_default_41 from '/home/user/Documents/projects/imjob-web/src/pages/admin/ads.vue?definePage&vue'
import _definePage_default_42 from '/home/user/Documents/projects/imjob-web/src/pages/admin/candidates.vue?definePage&vue'
import _definePage_default_43 from '/home/user/Documents/projects/imjob-web/src/pages/admin/companies.vue?definePage&vue'
import _definePage_default_44 from '/home/user/Documents/projects/imjob-web/src/pages/admin/feedback.vue?definePage&vue'
import _definePage_default_45 from '/home/user/Documents/projects/imjob-web/src/pages/admin/listing.vue?definePage&vue'
import _definePage_default_46 from '/home/user/Documents/projects/imjob-web/src/pages/auth/login.vue?definePage&vue'
import _definePage_default_47 from '/home/user/Documents/projects/imjob-web/src/pages/auth/register/index.vue?definePage&vue'
import _definePage_default_48 from '/home/user/Documents/projects/imjob-web/src/pages/auth/register/b-to-b.vue?definePage&vue'
import _definePage_default_49 from '/home/user/Documents/projects/imjob-web/src/pages/auth/register/company.vue?definePage&vue'
import _definePage_default_50 from '/home/user/Documents/projects/imjob-web/src/pages/auth/register/employees.vue?definePage&vue'
import _definePage_default_51 from '/home/user/Documents/projects/imjob-web/src/pages/auth/register/package.vue?definePage&vue'
import _definePage_default_52 from '/home/user/Documents/projects/imjob-web/src/pages/auth/register.vue?definePage&vue'
import _definePage_default_53 from '/home/user/Documents/projects/imjob-web/src/pages/auth/reset-pass.vue?definePage&vue'
import _definePage_default_54 from '/home/user/Documents/projects/imjob-web/src/pages/auth/user/basic.vue?definePage&vue'
import _definePage_default_55 from '/home/user/Documents/projects/imjob-web/src/pages/auth/user/computer-skills.vue?definePage&vue'
import _definePage_default_56 from '/home/user/Documents/projects/imjob-web/src/pages/auth/user/education.vue?definePage&vue'
import _definePage_default_57 from '/home/user/Documents/projects/imjob-web/src/pages/auth/user/experience.vue?definePage&vue'
import _definePage_default_58 from '/home/user/Documents/projects/imjob-web/src/pages/auth/user/hobby.vue?definePage&vue'
import _definePage_default_59 from '/home/user/Documents/projects/imjob-web/src/pages/auth/user/languages.vue?definePage&vue'
import _definePage_default_60 from '/home/user/Documents/projects/imjob-web/src/pages/auth/user/portfolio.vue?definePage&vue'
import _definePage_default_61 from '/home/user/Documents/projects/imjob-web/src/pages/auth/user/skills.vue?definePage&vue'
import _definePage_default_62 from '/home/user/Documents/projects/imjob-web/src/pages/auth/user.vue?definePage&vue'
import _definePage_default_63 from '/home/user/Documents/projects/imjob-web/src/pages/auth.vue?definePage&vue'
import _definePage_default_64 from '/home/user/Documents/projects/imjob-web/src/pages/company/company-information.vue?definePage&vue'
import _definePage_default_65 from '/home/user/Documents/projects/imjob-web/src/pages/company/listing-preview-btb.vue?definePage&vue'
import _definePage_default_66 from '/home/user/Documents/projects/imjob-web/src/pages/company/listing-preview.vue?definePage&vue'
import _definePage_default_67 from '/home/user/Documents/projects/imjob-web/src/pages/company/manage-user-profile.vue?definePage&vue'
import _definePage_default_68 from '/home/user/Documents/projects/imjob-web/src/pages/company/candidate.vue?definePage&vue'
import _definePage_default_69 from '/home/user/Documents/projects/imjob-web/src/pages/company/assigned-listings.vue?definePage&vue'
import _definePage_default_70 from '/home/user/Documents/projects/imjob-web/src/pages/company/clients.vue?definePage&vue'
import _definePage_default_71 from '/home/user/Documents/projects/imjob-web/src/pages/company/company-profile.vue?definePage&vue'
import _definePage_default_72 from '/home/user/Documents/projects/imjob-web/src/pages/company/home.vue?definePage&vue'
import _definePage_default_73 from '/home/user/Documents/projects/imjob-web/src/pages/company/listings.vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'HomePage',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/index.vue'),
        /* no children */
      },
  _definePage_default_0
  ),
  _mergeRouteRecord(
      {
        path: 'about',
        name: 'AboutPage',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/about.vue'),
        /* no children */
      },
  _definePage_default_2
  ),
      {
        path: 'manage',
        /* internal name: '//manage' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '/manage/chat-bot-page/:id?',
            name: 'ChatBotPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/chat-bot-page.vue'),
            /* no children */
          },
  _definePage_default_3
  ),
  _mergeRouteRecord(
          {
            path: '/manage/chat-main/:id?',
            name: 'ChatMain',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/chat-main.vue'),
            /* no children */
          },
  _definePage_default_4
  ),
  _mergeRouteRecord(
          {
            path: '/manage/chat-page/:id',
            name: 'ChatApplication',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/chat-application.vue'),
            /* no children */
          },
  _definePage_default_5
  ),
  _mergeRouteRecord(
          {
            path: '/manage/job-preview/:id',
            name: 'JobPreview',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/job-preview.vue'),
            /* no children */
          },
  _definePage_default_6
  ),
  _mergeRouteRecord(
          {
            path: '/manage/public-profile/:id',
            name: 'PublicProfile',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/public-profile.vue'),
            /* no children */
          },
  _definePage_default_7
  ),
  _mergeRouteRecord(
          {
            path: 'candidates',
            name: 'CandidatesPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/candidates.vue'),
            /* no children */
          },
  _definePage_default_8
  ),
  _mergeRouteRecord(
          {
            path: 'community',
            name: 'CommunityPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/community.vue'),
            /* no children */
          },
  _definePage_default_9
  ),
  _mergeRouteRecord(
          {
            path: 'company-candidates',
            name: 'CompanyCandidatesPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/company-candidates.vue'),
            /* no children */
          },
  _definePage_default_10
  ),
  _mergeRouteRecord(
          {
            path: 'contact',
            name: 'ContactPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/contact.vue'),
            /* no children */
          },
  _definePage_default_11
  ),
  _mergeRouteRecord(
          {
            path: 'cv',
            name: 'CvPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/cv.vue'),
            /* no children */
          },
  _definePage_default_12
  ),
  _mergeRouteRecord(
          {
            path: 'employers',
            name: 'EmployersPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/employers.vue'),
            /* no children */
          },
  _definePage_default_13
  ),
  _mergeRouteRecord(
          {
            path: 'feedback',
            name: 'FeedbackPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/feedback.vue'),
            /* no children */
          },
  _definePage_default_14
  ),
  _mergeRouteRecord(
          {
            path: 'invitations-list',
            name: 'InvitationsList',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/invitations-list.vue'),
            /* no children */
          },
  _definePage_default_15
  ),
  _mergeRouteRecord(
          {
            path: 'job-listing',
            name: 'JobListingPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/job-listing.vue'),
            /* no children */
          },
  _definePage_default_16
  ),
  _mergeRouteRecord(
          {
            path: 'job-saved',
            name: 'JobSaved',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/job-saved.vue'),
            /* no children */
          },
  _definePage_default_17
  ),
  _mergeRouteRecord(
          {
            path: 'my-applications',
            name: 'MyApplicationsPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/my-applications.vue'),
            /* no children */
          },
  _definePage_default_18
  ),
  _mergeRouteRecord(
          {
            path: 'notifications',
            name: 'NotificationsPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/notifications.vue'),
            /* no children */
          },
  _definePage_default_19
  ),
  _mergeRouteRecord(
          {
            path: 'posts',
            name: 'PostPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/posts.vue'),
            /* no children */
          },
  _definePage_default_20
  ),
  _mergeRouteRecord(
          {
            path: 'profile',
            name: 'ProfilePage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile.vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'basic',
                name: 'ProfileBasicInfo',
                component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/basic.vue'),
                /* no children */
              },
  _definePage_default_21
  ),
  _mergeRouteRecord(
              {
                path: 'computer-skills',
                name: 'ProfileComputerSkills',
                component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/computer-skills.vue'),
                /* no children */
              },
  _definePage_default_22
  ),
  _mergeRouteRecord(
              {
                path: 'education',
                name: 'ProfileEducation',
                component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/education.vue'),
                /* no children */
              },
  _definePage_default_23
  ),
  _mergeRouteRecord(
              {
                path: 'experience',
                name: 'ProfileExperience',
                component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/experience.vue'),
                /* no children */
              },
  _definePage_default_24
  ),
  _mergeRouteRecord(
              {
                path: 'hobby',
                name: 'ProfileHobby',
                component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/hobby.vue'),
                /* no children */
              },
  _definePage_default_25
  ),
  _mergeRouteRecord(
              {
                path: 'languages',
                name: 'ProfileLanguages',
                component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/languages.vue'),
                /* no children */
              },
  _definePage_default_26
  ),
  _mergeRouteRecord(
              {
                path: 'portfolio',
                name: 'ProfilePortfolio',
                component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/portfolio.vue'),
                /* no children */
              },
  _definePage_default_27
  ),
  _mergeRouteRecord(
              {
                path: 'skills',
                name: 'ProfileSkills',
                component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/profile/skills.vue'),
                /* no children */
              },
  _definePage_default_28
  )
            ],
          },
  _definePage_default_29
  ),
  _mergeRouteRecord(
          {
            path: 'reports',
            name: 'ReportsPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/reports.vue'),
            /* no children */
          },
  _definePage_default_30
  ),
  _mergeRouteRecord(
          {
            path: 'user-calendar',
            name: 'userCalendar',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/manage/user-calendar.vue'),
            /* no children */
          },
  _definePage_default_31
  )
        ],
      },
      {
        path: 'settings',
        /* internal name: '//settings' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'SettingsPage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/settings/index.vue'),
            children: [
  _mergeRouteRecord(
              {
                path: 'aboutme',
                name: 'SettingsAboutMe',
                component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/settings/index/aboutme.vue'),
                /* no children */
              },
  _definePage_default_32
  ),
  _mergeRouteRecord(
              {
                path: 'information',
                name: 'SettingsInformation',
                component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/settings/index/information.vue'),
                /* no children */
              },
  _definePage_default_33
  ),
  _mergeRouteRecord(
              {
                path: 'social',
                name: 'SettingsSocial',
                component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/settings/index/social.vue'),
                /* no children */
              },
  _definePage_default_34
  )
            ],
          },
  _definePage_default_35
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'test',
        name: 'TestPage',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/test.vue'),
        /* no children */
      },
  _definePage_default_36
  ),
      {
        path: 'verify',
        name: '//verify',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/index/verify.vue'),
        /* no children */
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/:path(.*)',
    name: 'CatchAllPage',
    component: () => import('/home/user/Documents/projects/imjob-web/src/pages/[...path].vue'),
    /* no children */
  },
  _definePage_default_37
  ),
  {
    path: '/admin',
    name: '/admin',
    component: () => import('/home/user/Documents/projects/imjob-web/src/pages/admin.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '/admin/company-information/:id',
        name: 'AdminCompanyInformation',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/admin/company-information.vue'),
        /* no children */
      },
  _definePage_default_38
  ),
  _mergeRouteRecord(
      {
        path: '/admin/reported/:id',
        name: 'ReoprtedData',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/admin/reported.vue'),
        /* no children */
      },
  _definePage_default_39
  ),
  _mergeRouteRecord(
      {
        path: 'admin',
        name: 'AdminAdmin',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/admin/admin.vue'),
        /* no children */
      },
  _definePage_default_40
  ),
  _mergeRouteRecord(
      {
        path: 'ads',
        name: 'AdminAds',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/admin/ads.vue'),
        /* no children */
      },
  _definePage_default_41
  ),
  _mergeRouteRecord(
      {
        path: 'candidates',
        name: 'AdminCandidates',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/admin/candidates.vue'),
        /* no children */
      },
  _definePage_default_42
  ),
  _mergeRouteRecord(
      {
        path: 'companies',
        name: 'AdminCompanies',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/admin/companies.vue'),
        /* no children */
      },
  _definePage_default_43
  ),
  _mergeRouteRecord(
      {
        path: 'feedback',
        name: 'AdminFeedbackPage',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/admin/feedback.vue'),
        /* no children */
      },
  _definePage_default_44
  ),
  _mergeRouteRecord(
      {
        path: 'listing',
        name: 'AdminReportedListings',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/admin/listing.vue'),
        /* no children */
      },
  _definePage_default_45
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/auth',
    name: 'AuthPage',
    component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: 'login',
        name: 'LoginPage',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/login.vue'),
        /* no children */
      },
  _definePage_default_46
  ),
  _mergeRouteRecord(
      {
        path: 'register',
        name: 'RegisterPage',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/register.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'RegisterComp',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/register/index.vue'),
            /* no children */
          },
  _definePage_default_47
  ),
  _mergeRouteRecord(
          {
            path: 'b-to-b',
            name: 'b2b',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/register/b-to-b.vue'),
            /* no children */
          },
  _definePage_default_48
  ),
  _mergeRouteRecord(
          {
            path: 'company',
            name: 'RegisterCompany',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/register/company.vue'),
            /* no children */
          },
  _definePage_default_49
  ),
  _mergeRouteRecord(
          {
            path: 'employees',
            name: 'RegisterEmployees',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/register/employees.vue'),
            /* no children */
          },
  _definePage_default_50
  ),
  _mergeRouteRecord(
          {
            path: 'package',
            name: 'RegisterPackage',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/register/package.vue'),
            /* no children */
          },
  _definePage_default_51
  )
        ],
      },
  _definePage_default_52
  ),
  _mergeRouteRecord(
      {
        path: 'reset-pass',
        name: 'ResetPass',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/reset-pass.vue'),
        /* no children */
      },
  _definePage_default_53
  ),
  _mergeRouteRecord(
      {
        path: 'user',
        name: 'UserPage',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/user.vue'),
        children: [
  _mergeRouteRecord(
          {
            path: 'basic',
            name: 'BasicInfo',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/user/basic.vue'),
            /* no children */
          },
  _definePage_default_54
  ),
  _mergeRouteRecord(
          {
            path: 'computer-skills',
            name: 'ComputerSkills',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/user/computer-skills.vue'),
            /* no children */
          },
  _definePage_default_55
  ),
  _mergeRouteRecord(
          {
            path: 'education',
            name: 'Education',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/user/education.vue'),
            /* no children */
          },
  _definePage_default_56
  ),
  _mergeRouteRecord(
          {
            path: 'experience',
            name: 'Experience',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/user/experience.vue'),
            /* no children */
          },
  _definePage_default_57
  ),
  _mergeRouteRecord(
          {
            path: 'hobby',
            name: 'Hobby',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/user/hobby.vue'),
            /* no children */
          },
  _definePage_default_58
  ),
  _mergeRouteRecord(
          {
            path: 'languages',
            name: 'Languages',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/user/languages.vue'),
            /* no children */
          },
  _definePage_default_59
  ),
  _mergeRouteRecord(
          {
            path: 'portfolio',
            name: 'Portfolio',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/user/portfolio.vue'),
            /* no children */
          },
  _definePage_default_60
  ),
  _mergeRouteRecord(
          {
            path: 'skills',
            name: 'Skills',
            component: () => import('/home/user/Documents/projects/imjob-web/src/pages/auth/user/skills.vue'),
            /* no children */
          },
  _definePage_default_61
  )
        ],
      },
  _definePage_default_62
  )
    ],
  },
  _definePage_default_63
  ),
  {
    path: '/company',
    name: '/company',
    component: () => import('/home/user/Documents/projects/imjob-web/src/pages/company.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '/company/company-information/:id',
        name: 'CompanyInformation',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/company/company-information.vue'),
        /* no children */
      },
  _definePage_default_64
  ),
  _mergeRouteRecord(
      {
        path: '/company/listing-preview-btb/:id',
        name: 'ListingPreviewBtB',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/company/listing-preview-btb.vue'),
        /* no children */
      },
  _definePage_default_65
  ),
  _mergeRouteRecord(
      {
        path: '/company/listing-preview/:id',
        name: 'ListingPreview',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/company/listing-preview.vue'),
        /* no children */
      },
  _definePage_default_66
  ),
  _mergeRouteRecord(
      {
        path: '/company/manage-user-profile/:id',
        name: 'ManageUserProfile',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/company/manage-user-profile.vue'),
        /* no children */
      },
  _definePage_default_67
  ),
  _mergeRouteRecord(
      {
        path: '/users/:id',
        name: 'CandidateData',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/company/candidate.vue'),
        /* no children */
      },
  _definePage_default_68
  ),
  _mergeRouteRecord(
      {
        path: 'assigned-listings',
        name: 'AssignedListing',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/company/assigned-listings.vue'),
        /* no children */
      },
  _definePage_default_69
  ),
  _mergeRouteRecord(
      {
        path: 'clients',
        name: 'ClientsPage',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/company/clients.vue'),
        /* no children */
      },
  _definePage_default_70
  ),
  _mergeRouteRecord(
      {
        path: 'company-profile',
        name: 'CompanyProfile',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/company/company-profile.vue'),
        /* no children */
      },
  _definePage_default_71
  ),
  _mergeRouteRecord(
      {
        path: 'home',
        name: 'CompanyHome',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/company/home.vue'),
        /* no children */
      },
  _definePage_default_72
  ),
  _mergeRouteRecord(
      {
        path: 'listings',
        name: 'CompanyListing',
        component: () => import('/home/user/Documents/projects/imjob-web/src/pages/company/listings.vue'),
        /* no children */
      },
  _definePage_default_73
  )
    ],
  }
]
