<script setup lang="ts">
// import { openNewListingModal } from "@/components/modals/modalDefinitions";
import api from "@/api";
import { $t } from "@/i18n";
import {
	type TTableColumn,
	type TTableRow,
} from "../../components/shared/table/ModernTable.vue";
// import { useToastStore } from "@/store/toast";
// import { useRouter } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";
import { useTokenStore } from "@/store/token";
import {
	opetModalNewClient,
	opetModalEditClient,
	openConfirmModal,
} from "@/components/modals/modalDefinitions";

definePage({
	name: "ClientsPage",
});
interface IListCountry {
	cnt_code: string;
	cnt_name: string;
}

// const toastStore = useToastStore();
const tokenStore = useTokenStore();
// const router = useRouter();
// table

const columns = reactive<TTableColumn[]>([
	{
		id: "cli_name",
		label: $t("app.clients.clientName"),
	},
	{
		id: "cli_description",
		label: $t("app.clients.description"),
	},

	{
		id: "job_count",
		label: $t("app.clients.listings"),
	},
	{
		id: "cli_status",
		label: $t("app.companies.status"),
	},
	{
		id: "cnt_code",
		label: $t("app.candidates.country"),
	},
	{
		id: "status",
		label: "",
	},
	// {
	// 	id: "delete",
	// 	label: "",
	// },
	// {
	// 	id: "action",
	// 	label: "",
	// },
]);

if (tokenStore.can("cli-rmv")) {
	columns.push({
		id: "delete",
		label: "",
	});
}
if (tokenStore.can("cli-edit")) {
	columns.push({
		id: "action",
		label: "",
	});
}

const manageCallApi = ref<boolean>(true);
const rows = ref<TTableRow[]>([]);
const load = ref(false);
const showNewListing = ref<boolean>(false);

// const showListData = ref<TTableRow | null>(null);

const date = ref<string | null>(null);
const search = ref<string>("");
const filterStatus = ref<any>(null);
const selectedCountry = ref<IListCountry | null>(null);
const countries = ref<IListCountry[] | []>([]);

async function getCountries() {
	try {
		const res = await api.getCountries();
		countries.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

function deselectCountries(evt: any, evtParsed: any) {
	selectedCountry.value = null;
}

async function getClients(reset = false) {
	load.value = true;
	manageCallApi.value = false;

	if (reset) {
		rows.value = [];
		manageCallApi.value = true;
	}
	interface IParams {
		cmp_id?: number; // optional ako je view company uzece se iz jwt-a
		limit: number;
		offset: number;
		search?: string;
		date?: string;
		cnt_code?: string;
	}

	const params: IParams = {
		limit: 20,
		offset: rows.value.length,
	};

	if (search.value !== "") {
		params.search = search.value;
	}
	if (date.value) {
		params.date = DateInstance.getUtcFromDate(date.value, "YYYY-MM-DD");
	}
	if (selectedCountry.value) {
		params.cnt_code = selectedCountry.value.cnt_code;
	}

	try {
		const res = await api.getClients(params);
		// console.log(res.data.data.data);
		rows.value.push(...res.data.data.data);
		manageCallApi.value = res.data.data.data.length <= 0 ? false : true;
	} catch (e) {
		console.log(e);
	} finally {
		load.value = false;
	}
}

function formatDate(date: string) {
	return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
}

function openModal() {
	opetModalNewClient({}, onConfirm);
}
function onConfirm() {
	console.log("pozovi podatke");
	getClients(true);
}
function setEdit(e: TTableRow) {
	console.log(e);
	opetModalEditClient({ cli_id: e.cli_id }, onConfirm);
}

function manageClient(e: TTableRow, action: string) {
	// action 'restore' 'archive'

	openConfirmModal(
		{ name: e.cli_name, data: e },
		confirmRemoveRestore,
		action === "restore" ? "Restore this client?" : "Archive this client?",
	);
}

async function confirmRemoveRestore(e: TTableRow) {
	try {
		const res = await api.removeClient({ cli_id: e.cli_id });
		console.log(res);
		await getClients(true);
	} catch (e) {
		console.log(e);
	}
}

function getNameCountry(e: string | null) {
	if (e) {
		const res = countries.value.find((el: IListCountry) => el.cnt_code === e);
		return res?.cnt_name ?? "";
	}
	return "";
}
watch([filterStatus, search, date, selectedCountry], async () => {
	await getClients(true);
});

onMounted(async () => {
	await getClients();
	await getCountries();
});
</script>

<template lang="pug">
.client-listing(v-if="!showNewListing")
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.clients.clientsList") }}
			.wrapp-filters
				search-input(
					placeholder="Type something...",
					:search-value="search",
					@update:search-value="search = $event"
				)
				//- input(v-model="date", type="date", placeholder="Date created")
				//- .date
					modern-date-picker(
						v-model="date",
						:enable-time-picker="false",
						style="width: 150px",
						placeholder="Registry date"
					)
				.dropdown
					multiselect-form(
						v-model="selectedCountry",
						:options="countries",
						label="cnt_name",
						value-prop="cnt_code",
						placeholder="All countries",
						:can-deselect="true",
						@deselect="deselectCountries"
					)
			.wrapp-action(v-if="tokenStore.can('cli-add')")
				.btn(@click="openModal")
					span {{ $t("app.clients.addNew") }}
		modern-table(
			v-if="rows.length > 0",
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="false",
			:isInfiniteScroll="manageCallApi",
			style="max-height: 700px",
			@scroll-more="getClients"
		)
			template(#[`table-body-cell`]="slotProps")
				td(
					v-if="slotProps.column.id == 'job_expires' || slotProps.column.id == 'job_created'"
				)
					.dateTime
						span {{ formatDate(slotProps.row[slotProps.column.id]) }}

				td.status(v-else-if="slotProps.column.id == 'status'")
					.table-status(
						v-if="slotProps.row.cli_deleted",
						:class="{ unlisted: slotProps.row.cli_deleted }",
						style="min-width: 92px; width: 92px; min-height: 29px"
					) {{ $t("app.clients.archived") }}
				td.status(v-else-if="slotProps.column.id == 'delete'")
					button.btn-s(
						v-if="slotProps.row.cli_deleted",
						style="min-width: 92px; width: 92px; min-height: 29px",
						@click="manageClient(slotProps.row, 'restore')"
					) {{ $t("app.clients.restore") }}
					button.alt.btn-s(
						v-else,
						style="min-width: 92px; width: 92px; min-height: 29px",
						@click="manageClient(slotProps.row, 'archive')"
					) {{ $t("app.clients.archive") }}
				td(v-if="slotProps.column.id === 'cnt_code'")
					span {{ getNameCountry(slotProps.row[slotProps.column.id]) }}
				td.action(
					v-else-if="slotProps.column.id == 'action'",
					style="width: 120px"
				)
					span(@click="setEdit(slotProps.row)")
						img(src="@/assets/image/actionIcons/edit.svg")

				td(v-else, :class="{ bold: slotProps.column.id === 'job_title' }") {{ slotProps.row[slotProps.column.id] }}
		.loader-wrap-intern(v-else-if="load")
			.loader
		.wrapp-no-fund-data-table(v-else)
			img(src="@/assets/image/noCandidatesFund.png")
//- listing-preview(
	v-if="showListData",
	:data="showListData",
	@edit-list="closeNewList",
	@return-on-list="closeNewList"
	)
</template>

<style lang="scss" scoped>
.client-listing {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
		}
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				// margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				margin-top: $margin-title-up;
			}
		}
		.wrapp-filters {
			width: calc(100% - 350px);
			display: flex;
			flex-wrap: wrap;
			// align-items: center;
			justify-content: flex-end;

			.dropdown {
				padding: 0 10px;
				border-radius: $input-radius;
				// color: $text-color;
				// font-size: 14px;
				outline: none;
				// background-color: transparent;
				// text-indent: 10px;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 150px;
				align-items: center;
				// text-overflow: ellipsis;
				margin-right: 10px;
			}
			.date {
				padding: 0 10px 0 2px;
				margin-top: 10px;
			}
		}
		.wrapp-action {
			display: flex;
			// align-items: center;
			.btn {
				margin-top: 10px;
			}
		}
	}
}

.action {
	max-width: 40px;
	margin-left: auto;
	display: flex;
	align-items: center;
	height: 40px !important;
	position: relative;

	span {
		cursor: pointer;
	}

	img {
		cursor: pointer;
	}
}
</style>
<style lang="scss">
.client-listing {
	.main {
		.info-row {
			box-sizing: border-box;
			max-width: 100%;
			padding: 25px 10px;
		}
		td {
			font-size: 13px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
			padding-left: 5px;
			padding-right: 5px;
			// cursor: pointer;
		}
		.bold {
			font-weight: 700;
		}
		.btn-s {
			box-sizing: border-box;
			min-width: 92px !important;
			min-height: 29px !important;
			height: 29px !important;
			border-radius: 84px;
			padding: 7px 20px;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
		}
	}
	.search-wrapper {
		margin-right: 10px;
		margin-top: 10px;
		width: 20%;
		min-width: 150px !important;
		input {
			min-width: 150px !important;
			max-width: 100% !important;
			width: 100% !important;
		}
	}
	input[type="date"],
	.dropdown {
		margin-top: 10px;
	}
}
</style>
