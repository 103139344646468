<script setup lang="ts">
definePage({
	name: "RegisterPage",
});
</script>

<template lang="pug">
#register-page
	router-view.comp-section
</template>

<style lang="scss" scoped>
#register-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px 170px;
	height: 100%;
	width: 100%;
	max-width: 1500px;

	.comp-section {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;
		flex-direction: column-reverse;
	}

	@media screen and (width >= 768px) {
		flex-direction: row;

		.comp-section {
			min-width: 400px;

			> * {
				width: 100%;
				padding: 0 50px;
			}
		}
	}

	@media screen and (width >= 1024px) {
		.comp-section {
			min-width: 400px;
			justify-content: space-between;
			flex-direction: row;

			> * {
				width: 100%;
			}
		}
	}
}
</style>
