<script setup lang="ts">
import api from "@/api";
import { $t } from "@/i18n";
import { useRoute, useRouter } from "vue-router";
// import { openConfirmModal } from "@/components/modals/modalDefinitions";
import {
	type TTableColumn,
	type TTableRow,
} from "@/components/shared/table/ModernTable.vue";
import { DateInstance } from "@/assets/js/dateHelper";
// import { useTokenStore } from "@/store/token";
// import type { TFileObj } from "@/assets/js/types";

import type { CWebsocket } from "@/assets/js/websocketHelper";
import { useSocketStore } from "@/store/socket";
import { useMessageStore } from "@/store/message";

definePage({
	name: "ChatMain",
	path: "/manage/chat-main/:id?", // param not required
});

const messageStore = useMessageStore();

const socket = useSocketStore();

socket.setOptions({
	cb: {
		onMessageData: async (
			ws: CWebsocket,
			evt: WebSocketEventMap["message"],
			message: any,
		) => {
			// console.log("*******************************++");
			console.log(message);
			messageStore.triggerMessageGetAll(null, message, async () => {});

			if (message?.type === "new-message") {
				const index = rows.value.findIndex((obj) =>
					message?.usr_id
						? obj.usr_id === message?.usr_id
						: obj.usr_id === message?.usr_id_to,
				);
				// console.log("index", index);

				if (
					selectedRoom.value !== null &&
					(selectedRoom.value?.usr_id === message?.usr_id ||
						selectedRoom.value?.usr_id === message?.usr_id_to)
				) {
					await getMessages(false, "new");
				} else if ((index || index === 0) && index !== -1) {
					rows.value[index].unread = 1;
				} else if (index === -1 && selectedRoom.value?.newCh) {
					await getMessages(true, "new");
					// await getRooms(); //call in new msg
				} else if (
					index === -1 &&
					(!selectedRoom.value || !selectedRoom.value?.newCh)
				) {
					await getRooms();
				}
				// set new room
				if (selectedRoom.value?.newCh) {
					await getRooms();
					const obj = rows.value.find((obj) => obj.usr_id === paramId.value);
					if (obj) {
						selectedRoom.value = obj;
					}
				}
				setNewMsgPositions(message.usr_id);
			}

			// messageStore.triggerMessageGet(app_id.value, () => {
			// 	console.log("triger message");
			// 	getMessages(false, "new");
			// });
		},
	},
});

function setNewMsgPositions(id: number) {
	// removed logic because there is no sorting on beckend
	console.log("Set up new room", id);
	// for (const [i, item] of rows.value.entries()) {
	// 	if (item.usr_id === id) {
	// 		rows.value.splice(i, 1);
	// 		rows.value.unshift(item);
	// 	}
	// }
}
// socket.initSocket();

const route = useRoute();
const router = useRouter();
// const tokenStore = useTokenStore();

const selectedRoom = ref<any>(null);
const searchInRooms = ref("");

const messages = ref<any[]>([]);
const searchInChat = ref("");
const isInfiniteScroll = ref(true);

const columns = reactive<TTableColumn[]>([
	{
		id: "img_id",
		label: "",
	},
	{
		id: "usr_name",
		label: $t("app.messages.nameCompanyListing"),
	},
	// {
	// 	id: "msg_created",
	// 	label: $t("app.messages.dateTime"),
	// },
	{
		id: "unread",
		label: "",
	},
]);

const rows = ref<TTableRow[]>([
	// {
	// 	img_id: 8_092_158_048_697_964,
	// 	usr_name: "Test Prezime",
	// 	cmp_name: "Test Company",
	// 	msg_created: "2024-04-24 13:46:47",
	// },
]);

async function getRooms() {
	type TParams = {
		search?: string;
	};
	const params: TParams = {};
	if (searchInRooms.value) {
		params.search = searchInRooms.value;
	}
	try {
		console.log("zameni sa apijem za sobe koji dobijes", params);
		try {
			const res = await api.getRoomsInternal(params);
			// console.log(res.data);
			rows.value = res.data.data;

			// messages.value.unshift(...res.data.data.reverse());
			// isInfiniteScroll.value = false;
		} catch (e) {
			console.log(e);
		}
		// const res = await api.getRooms(params); zameni sa apijem koji dobijes
		// console.log(res.data);
		// messages.value.push(...res.data.data);
		// messages.value.unshift(...res.data.data.reverse());
		// isInfiniteScroll.value = false;
	} catch (e) {
		console.log(e);
	}
}

async function getMessages(resetChat = false, view = "old") {
	console.log("dobavi poruke");
	if (!selectedRoom.value) {
		return;
	}
	if (resetChat) {
		messages.value = [];
	}
	type TParams = {
		last_msg_id: number | null;
		usr_id: number;
		chat_type: string;
		search?: string;
		view: "old" | "new" | string;
	};

	const params: TParams = {
		last_msg_id:
			view === "old"
				? messages.value.length <= 0
					? null
					: messages.value.at(-1).msg_id
				: messages.value.length <= 0
					? null
					: messages.value.at(0).msg_id,
		usr_id: selectedRoom.value.usr_id,
		chat_type: selectedRoom.value.chat_type,
		view: view,
	};

	if (searchInChat.value) {
		params.search = searchInChat.value;
	}

	try {
		const res = await api.getMessagesInternal(params);

		if (view === "old") {
			messages.value.push(...res.data.data.reverse());
		} else if (view === "new") {
			messages.value.unshift(...res.data.data);
			await setSeen(res.data.data[0].msg_id);
		}

		if (res.data.data.length > 0) {
			isInfiniteScroll.value = true;
		}
	} catch (e) {
		console.log(e);
	}

	// messages.value.push(...res.data.data);
}

// function open(e: TFileObj) {
// 	console.log(e);
// 	console.log("pozvati komponentu za prikaz fajlova");
// }

// function blockUser() {
// openConfirmModal(
// { name: "name group or user", data: "set chat room or user" },
// confirmBlock,
// "Block this chat?",
// );
// }
//
// function confirmBlock() {
// console.log("pozovi api za blokiranje usera");
// }

function formatDate(date: string, format = "DD. MMM YYYY.") {
	// return dayjs.utc(date).local().format("DD.MM.YYYY.");
	if (date) {
		return DateInstance.getFormatDTLocale(date, format);
	}
}

watch(
	[searchInChat, searchInRooms],
	async ([inChatNew, inRoomsNew], [inChatOld, inRoomsOld]) => {
		if (inChatNew !== inChatOld) {
			getMessages(true);
			console.log("pozovi poruke sa pretragom", inChatNew);
		}
		if (inRoomsNew !== inRoomsOld) {
			getRooms();
			console.log("pozovi sobe sa pretragom", inRoomsNew);
		}
	},
);

async function send(e: {
	msg: string;
	file: number | null;
	reply: number | null;
}) {
	// const regex = /^(<p><br><\/p>|<p>\s*<\/p>)+$/; // /^(<p><br><\/p>)+$/;
	// const regex2 = /^(<p>\s*<\/p>)+$/;

	// console.log(e.msg, "regex.test(e.msg)");
	// if (regex.test(e.msg) || e.msg.trim() === "" || regex2.test(e.msg)) {
	// 	console.log(e.msg);
	// 	return;
	// }
	console.log(e);

	type TParams = {
		// app_id: any;
		msg_content: string;
		usr_id_to?: number | null;
		file_id?: number; //  any[];
		room?: string;
		msg_id_parent?: number;
		type: string;
	};
	const params: TParams = {
		msg_content: e.msg,
		usr_id_to: selectedRoom.value.usr_id,
		type: "sendMessageInternal",
	}; //new FormData();
	// params.append("msg_content", e.msg);

	if (selectedRoom.value) {
		params.room = selectedRoom.value.chat_type;
		params.usr_id_to = selectedRoom.value.usr_id;
	}

	if (e.reply) {
		params.msg_id_parent = e.reply;
	}

	if (e.file) {
		params.file_id = e.file;
	}

	socket.sendMessage(JSON.stringify(params)); //await api.addMessage(params);
	// if (selectedRoom.value.newCh) {
	// 	await getRooms();
	// 	const obj = rows.value.find((obj) => obj.usr_id === paramId.value);
	// 	if (obj) {
	// 		selectedRoom.value = obj;
	// 	}
	// }
	// selectedRoom.value = obj ?? {
	// usr_id: route.params.id,
	// chat_type: "internal",
	// };

	messageStore.setSendMsgTo(null);
}
// function setRow(e: TTableRow) {
// 	console.log(e);
// 	selectedRoom.value = e;
// 	getMessages(true);
// }

async function setRow(e: TTableRow) {
	console.log("set new chat msg", e);
	selectedRoom.value = e;
	router.replace({ name: "ChatMain", params: { id: e.usr_id } });
	await getMessages(true);
	if (e.unread === 1 && messages.value.length > 0) {
		console.log(messages.value[0].msg_id);
		// setLocallySeen();
		await setSeen(messages.value[0].msg_id);
	}
}
async function setSeen(id: number) {
	try {
		const res = await api.appMessagesSeen({ msg_id: id });
		if (res.data?.res === "OK") {
			setLocallySeen();
		}
	} catch (e) {
		console.log(e);
	}
}

function setLocallySeen() {
	const index = rows.value.findIndex(
		(obj) =>
			obj.usr_id === selectedRoom.value?.usr_id &&
			obj.chat_type === selectedRoom.value?.chat_type,
	);
	if ((index || index === 0) && index !== -1) rows.value[index].unread = 0;
	console.log(index);
}

// function setLocallyUnseen(id: number) {
// 	const index = rows.value.findIndex((obj) => obj.usr_id === id);
// 	if (index || index === 0) rows.value[index].unread = 1;
// 	console.log(index);
// }

const paramId = computed(() => {
	return Number(route.params.id) || null;
});
onMounted(async () => {
	await getRooms();
	if (route.params?.id) {
		console.log(route.params?.id);

		const obj = rows.value.find((obj) => obj.usr_id === paramId.value);
		// selectedRoom.value = obj ?? {
		// usr_id: route.params.id,
		// chat_type: "internal",
		// };
		// console.log("aaaaaaaaaaaaaa");
		if (obj) {
			selectedRoom.value = obj;
			getMessages(true);
		} else {
			selectedRoom.value = {
				usr_id: route.params.id,
				chat_type: "internal",
				usr_name: messageStore.msgTo?.usr_name,
				job_id: messageStore.msgTo?.job_id,
				newCh: true,
			};
		}
	}
});
</script>

<template lang="pug">
.page.chat
	.main
		.wrapp-content
			.wrapp-rooms
				.up
					.wrapp-title
						.line
						.title {{ $t("app.messages.inbox") }}
				.wrapp-filter
					search-input(
						style="max-width: 100%",
						:placeholder="$t('app.messages.searchConversation')",
						:search-value="searchInRooms",
						@update:search-value="searchInRooms = $event"
					)

				modern-table(
					v-if="rows.length > 0 || selectedRoom",
					:rows="rows",
					:columns="columns",
					:has-border="false",
					:display-additional-row="false",
					:isInfiniteScroll="false",
					style="max-height: 700px"
				)
					template(#[`table-body-cell`]="slotProps")
						td(
							v-if="slotProps.column.id == 'msg_created'",
							style="width: 110px",
							:class="{ selected: selectedRoom?.usr_id === slotProps.row.usr_id }",
							@click="setRow(slotProps.row)"
						)
							.dateTime
								span {{ formatDate(slotProps.row[slotProps.column.id]) }}
								//- br
								span.time {{ formatDate(slotProps.row[slotProps.column.id], "HH:mm") }}
						td(
							v-if="slotProps.column.id == 'img_id'",
							style="width: 36px; padding-right: 0px",
							:class="{ selected: selectedRoom?.usr_id === slotProps.row.usr_id }",
							@click="setRow(slotProps.row)"
						)
							avatar-image(
								:user="{ img_id: slotProps.row.img_id, usr_name: slotProps.row.usr_name }"
							) 
						td.td-name(
							v-else-if="slotProps.column.id == 'usr_name'",
							:class="{ selected: selectedRoom?.usr_id === slotProps.row.usr_id }",
							@click="setRow(slotProps.row)"
						)
							.chat-name {{ slotProps.row[slotProps.column.id] }}
							.cmp-name {{ slotProps.row.cmp_name }}
						td(
							v-else-if="slotProps.column.id === 'unread'",
							:class="{ selected: selectedRoom?.usr_id === slotProps.row.usr_id }"
						) 
							.circle(v-if="slotProps.row[slotProps.column.id]")

				//- :rooms="[]"
			.wrapp-main
				.title-up
					.main-info
						.name(v-if="selectedRoom") {{ selectedRoom.usr_name }}
						.listing(v-if="selectedRoom") {{ selectedRoom.cmp_name }}
					//- search-input(
						v-if="selectedRoom",
						style="max-width: 100%",
						:placeholder="$t('app.messages.searchInConversation')",
						:search-value="searchInChat",
						@update:search-value="searchInChat = $event"
						)
				.title-down
					.btn.alt(
						v-if="false",
						@click="send({ msg: 'test', file: null, reply: null })"
					)
						span {{ $t("app.messages.block") }}
				wrapp-messages(
					v-if="rows.length > 0 || selectedRoom",
					:messages="messages",
					:isInfiniteScroll="isInfiniteScroll",
					:room="selectedRoom",
					@scroll-more="getMessages",
					@send="send"
				)
				.wrapp-no-rooms(v-if="rows.length <= 0 && !selectedRoom")
					div {{ $t("app.messages.noRoomsMsg") }}
</template>

<style lang="scss" scoped>
.page.chat {
	// padding: 20px;
	// height: 100%;
	// width: 100%;
	font-family: "Open Sans", sans-serif;
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
			align-items: center;
		}

		.wrapp-title {
			align-self: flex-start;
			width: fit-content;
			margin-bottom: 30px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				display: inline;
				//	font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
			}
		}

		// main data
		.wrapp-content {
			display: flex;
			min-height: 100%;
			justify-content: space-between;

			// messages
			.wrapp-main {
				width: 65%;
				min-height: 100%;
				// background: #e44e75;
				.title-up {
					margin-top: 21px;
					font-family: "Open Sans", sans-serif;
					display: flex;
					justify-content: space-between;

					.main-info {
						.name {
							font-size: 16px;
							font-weight: 700;
							line-height: 21.79px;
							text-align: left;
						}
						.listing {
							font-size: 16px;
							font-weight: 400;
							line-height: 21.79px;
							text-align: left;
						}
					}
				}
				.title-down {
					margin-top: 3px;
					margin-bottom: 15px;
					text-align: right;
				}
			}

			// left side menu
			.wrapp-rooms {
				width: calc(35% - 15px);
				margin-right: 15px;
				overflow: auto;

				.wrapp-filter {
					width: 278px;
					margin-bottom: 30px;
				}

				td {
					padding: 10px;
					cursor: pointer;
				}

				.td-name {
					font-family: "Open Sans", sans-serif;
					.chat-name {
						font-size: 13px;
						font-weight: 700;
						line-height: 15px;
						text-align: left;
					}
					.cmp-name {
						font-size: 13px;
						font-weight: 400;
						line-height: 15px;
						text-align: left;
					}
				}

				.dateTime {
					display: flex;
					height: 40px;
					flex-direction: column;
					justify-content: center;
					// font-family: Archivo, sans-serif;

					span {
						font-size: 13px;
						font-weight: 600;
						line-height: 15px;
						letter-spacing: 0;
						text-align: left;
					}

					.time {
						font-size: 11px;
						font-weight: 400;
						line-height: 15px;
						letter-spacing: 0;
						text-align: left;
					}
				}
			}
		}
		.wrapp-no-rooms {
			display: flex;
			height: 70%;
			// justify-content: center;
			align-items: center;
			font-weight: bold;
		}
	}

	.selected {
		background: $app-accent-color4;
	}
	.circle {
		background: $app-accent-color3;
		color: $background-color;
		// padding: 0 6px;
		// border-radius: 10px;
		// font-weight: 700;
		// font-size: 0.9em;

		// new
		width: 15px;
		height: 15px;
		border-radius: 50%;
		margin-right: 5px;
		margin-left: auto;
	}

	.wrapp-messages {
		width: 100%;
	}
}

@keyframes line {
	from {
		width: 0%;
	}
	to {
		width: 100%;
	}
}
</style>
