<script setup lang="ts">
// import { openNewListingModal } from "@/components/modals/modalDefinitions";
import api from "@/api";
import { $t } from "@/i18n";
import {
	type TTableColumn,
	type TTableRow,
} from "../../components/shared/table/ModernTable.vue";
import { useToastStore } from "@/store/toast";
import { useRouter } from "vue-router";
import { DateInstance } from "@/assets/js/dateHelper";
import type { ModelValue } from "@vuepic/vue-datepicker";
import { useTokenStore } from "@/store/token";

definePage({
	name: "CompanyListing",
});
interface IListCountry {
	cnt_code: string;
	cnt_name: string;
}

const toastStore = useToastStore();
const tokenStore = useTokenStore();
const router = useRouter();
// table

const columns = ref<TTableColumn[]>([
	{
		id: "job_title",
		label: $t("app.companies.listingName"),
	},
	{
		id: "job_created",
		label: $t("app.companies.datePosted"),
	},
	{
		id: "job_expires",
		label: $t("app.companies.expirationDate"),
	},
	{
		id: "job_status",
		label: $t("app.companies.status"),
	},
	{
		id: "usr_name",
		label: $t("app.companies.responsiblePerson"),
	},
	{
		id: "cnt_name",
		label: $t("app.companies.country"),
	},
	{
		id: "application_count",
		label: $t("app.companies.applicationNumber"),
	},
	{
		id: "copy",
		label: "Copy link",
	},
	// {
	// 	id: "action",
	// 	label: "",
	// },
]);

if (tokenStore.can("job-edit")) {
	columns.value.push({
		id: "action",
		label: "",
	});
}

const manageCallApi = ref<boolean>(true);
const rows = ref<TTableRow[]>([]);

const showNewListing = ref<boolean>(false);

const showOptionsData = ref<TTableRow | null>(null);

// const showListData = ref<TTableRow | null>(null);
const load = ref(false);
const date = ref<string | null>(null);
const search = ref<string>("");
const filterStatus = ref<any>(null);
const selectedCountry = ref<IListCountry | null>(null);
const countries = ref<IListCountry[] | []>([]);
const allFilters = reactive([
	{ label: "Cancelled" },
	{ label: "Finished" },
	{ label: "Unlisted" },
	{ label: "Paused" },
	{ label: "Published" },
	{ label: "Draft" },
]);

async function getCountries() {
	try {
		const res = await api.getCountries();
		countries.value = res.data.data;
	} catch (e) {
		console.log(e);
	}
}

function deselectCountries(evt: any, evtParsed: any) {
	selectedCountry.value = null;
}
function deselectStatus(evt: any, evtParsed: any) {
	console.log(filterStatus.value);
	filterStatus.value = null;
}

async function getJobs(reset = false) {
	load.value = true;
	manageCallApi.value = false;
	if (reset) {
		rows.value = [];
		manageCallApi.value = true;
	}
	interface IParams {
		view: "company" | "public";
		cmp_id?: number; // optional ako je view company uzece se iz jwt-a
		// filter - (po statusu),
		// ako je view comapny onda moze biti cancelled, finished, unlisted, paused, published, draft,
		// ako je view public onda moze da bude published i paused
		filter?:
			| "Cancelled"
			| "Finished"
			| "Unlisted"
			| "Paused"
			| "Published"
			| "Draft";
		limit: number;
		offset: number;
		search?: string;
		date?: string;
		cnt_code?: string;
	}

	const params: IParams = {
		view: "company",
		limit: 20,
		offset: rows.value.length,
	};

	if (search.value !== "") {
		params.search = search.value;
	}
	if (filterStatus.value) {
		params.filter = filterStatus.value.label.toLowerCase();
	}
	if (date.value) {
		params.date = DateInstance.getUtcFromDate(date.value, "YYYY-MM-DD");
	}
	if (selectedCountry.value) {
		params.cnt_code = selectedCountry.value.cnt_code;
	}

	try {
		const res = await api.getJobs(params);
		// console.log(res.data.data.data);
		rows.value.push(...res.data.data.data);
		manageCallApi.value = res.data.data.data.length <= 0 ? false : true;
	} catch (e) {
		console.log(e);
	} finally {
		load.value = false;
	}
}

function openModal() {
	// openNewListingModal();
	showOptionsData.value = null;
	showNewListing.value = true;
}
function formatDate(date: string) {
	return DateInstance.getFormatDTLocale(date, "DD. MMM YYYY.");
}
onMounted(async () => {
	await getJobs();
	await getCountries();
});

function showOneListing(e: any) {
	console.log(e);
	console.log("showOneListing");
	// showListData.value = e;
	// showNewListing.value = true;

	void router.push({
		name: "ListingPreview",
		params: { id: e.job_id },
	});
}
async function closeNewList(e = false) {
	if (e) {
		await getJobs(true);
	}
	showNewListing.value = false;
	showOptionsData.value = null;
	// showListData.value = null;
}

async function changeStatus(e: any, status: string) {
	console.log(e);
	await sendEdit({ job_id: e.job_id, job_status: status });
	showOptionsData.value = null;
	// showListData.value = null;
}

async function sendEdit(params: any) {
	// cancelled,paused,finished,published,unlisted,draft
	try {
		console.log(params);
		const res = await api.jobsEdit(params);
		console.log(res.data.data);
		toastStore.openToastSuccess("Data changed successfully");
		await getJobs(true);
	} catch (e) {
		console.log(e);
	}
}

watch([filterStatus, search, date, selectedCountry], async () => {
	await getJobs(true);
});
function handleDate(e: ModelValue) {
	console.log(e);
}
function copy(id: number) {
	// let link = window.location.href.replace(
	// 	"/company/listing-preview",
	// 	"/manage/job-preview",
	// );
	let link = window.origin + "/manage/job-preview/" + id;

	navigator.clipboard.writeText(link);
	toastStore.openToastInfo($t("app.messages.copyLink"));
}
</script>

<template lang="pug">
.company-listing(v-if="!showNewListing")
	.main
		.up
			.wrapp-title
				.line
				.title {{ $t("app.companies.companyListing") }}
			.wrapp-filters
				search-input(
					placeholder="Type listing name",
					:search-value="search",
					@update:search-value="search = $event"
				)
				//- input(v-model="date", type="date", placeholder="Date created")
				.date
					modern-date-picker(
						v-model="date",
						:enable-time-picker="false",
						style="width: 150px",
						placeholder="Expires until",
						@update:model-value="handleDate"
					)
				.dropdown
					multiselect-form(
						v-model="filterStatus",
						:options="allFilters",
						label="label",
						value-prop="label",
						placeholder="Filter",
						:can-deselect="true",
						@deselect="deselectStatus"
					)
				.dropdown
					multiselect-form(
						v-model="selectedCountry",
						:options="countries",
						label="cnt_name",
						value-prop="cnt_code",
						placeholder="All countries",
						:can-deselect="true",
						@deselect="deselectCountries"
					)
			.wrapp-action(v-if="tokenStore.can('job-add')")
				.btn(@click="openModal")
					span Add new listing
		modern-table(
			v-if="rows.length > 0",
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="true",
			:isInfiniteScroll="manageCallApi",
			style="max-height: 700px",
			@scroll-more="getJobs"
		)
			template(#[`table-body-cell`]="slotProps")
				td(
					v-if="slotProps.column.id == 'job_expires' || slotProps.column.id == 'job_created'",
					@click="showOneListing(slotProps.row)"
				)
					.dateTime
						span {{ formatDate(slotProps.row[slotProps.column.id]) }}

				td.status(v-else-if="slotProps.column.id == 'job_status'")
					//- cancelled, finished, unlisted, paused, published, draft,
					.table-status(
						:class="{ approved: slotProps.row.job_status === 'Published', pending: slotProps.row.job_status === 'Paused', finished: slotProps.row.job_status === 'Finished', cancelled: slotProps.row.job_status === 'Cancelled', unlisted: slotProps.row.job_status === 'Unlisted', draft: slotProps.row.job_status === 'Draft' }",
						style="min-width: 92px; width: 92px; min-height: 29px"
					) {{ slotProps.row.job_status }}
				td(v-else-if="slotProps.column.id === 'copy'", style="cursor: initial")
					img.copy(
						v-if="slotProps.row.job_status.toLowerCase() === 'unlisted' || slotProps.row.job_status.toLowerCase() === 'published'",
						src="@/assets/image/icons/copy.svg",
						title="copy",
						style="cursor: pointer",
						@click="copy(slotProps.row.job_id)"
					)
				td.action(
					v-else-if="slotProps.column.id == 'action'",
					style="width: 120px"
				)
					span
						img(
							src="@/assets/image/actionIcons/more.svg",
							@click="showOptionsData = slotProps.row"
						)
					.show-reports-data(
						v-if="showOptionsData?.job_id === slotProps.row?.job_id"
					)
						.close-btn(data-name="close-action", @click="showOptionsData = null")
							svg.close-icon(
								width="16",
								height="16",
								viewBox="0 0 16 16",
								fill="none",
								xmlns="http://www.w3.org/2000/svg"
							)
								path(
									fill-rule="evenodd",
									clip-rule="evenodd",
									d="M8.00009 9.41431L13.6709 15.0851L15.0851 13.6709L9.41431 8.00009L15.0851 2.32925L13.6709 0.915039L8.00009 6.58588L2.32925 0.915039L0.915039 2.32925L6.58588 8.00009L0.915039 13.6709L2.32925 15.0851L8.00009 9.41431Z",
									fill="#575758"
								)
						.wrapp-info(
							v-if="slotProps.row.job_status !== 'Paused'",
							@click="changeStatus(slotProps.row, 'Paused')"
						)
							span {{ $t("app.companies.pauseListing") }}
						.wrapp-info(
							v-if="slotProps.row.job_status !== 'Finished'",
							@click="changeStatus(slotProps.row, 'Finished')"
						)
							span {{ $t("app.companies.finishListing") }}
						.wrapp-info(
							v-if="slotProps.row.job_status !== 'Published'",
							@click="changeStatus(slotProps.row, 'Published')"
						)
							span {{ $t("app.companies.publishListing") }}
						.wrapp-info(
							v-if="slotProps.row.job_status !== 'Unlisted'",
							@click="changeStatus(slotProps.row, 'Unlisted')"
						)
							span {{ $t("app.companies.unlistListing") }}
						.wrapp-info(
							v-if="slotProps.row.job_status !== 'Draft'",
							@click="changeStatus(slotProps.row, 'Draft')"
						)
							span {{ $t("app.companies.draftListing") }}
						.wrapp-info(
							v-if="slotProps.row.job_status !== 'Cancelled'",
							@click="changeStatus(slotProps.row, 'Cancelled')"
						)
							span {{ $t("app.companies.cancelListing") }}
				td(
					v-else,
					:class="{ bold: slotProps.column.id === 'job_title' }",
					@click="showOneListing(slotProps.row)"
				) {{ slotProps.row[slotProps.column.id] }}
			template(#table-body-extra-row-content="slotProps")
				transition(name="show-row")
					td.info-row(
						v-show="showOptionsData?.job_id === slotProps.row?.job_id",
						:colspan="columns.length"
					)
		.loader-wrap-intern(v-else-if="load")
			.loader

		.wrapp-no-fund-data-table(v-else)
			img(src="@/assets/image/no_job.png", alt="")

new-listing(
	v-if="showNewListing",
	:data="showOptionsData",
	@return-on-list="closeNewList"
)
//- listing-preview(
	v-if="showListData",
	:data="showListData",
	@edit-list="closeNewList",
	@return-on-list="closeNewList"
	)
</template>

<style lang="scss" scoped>
.company-listing {
	max-width: 1224px;
	border-radius: 10px;
	// border: $border;
	background: $background-color;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.main {
		width: 100%;
		// max-width: 784px;

		.up {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
		}
		.wrapp-title {
			width: fit-content;
			margin-bottom: 30px;
			margin-right: 50px;
			.line {
				display: block;
				// background: $gradient-line3;
				// height: 5px;
				height: 0;
				width: 100%;
				// margin-bottom: 14px;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
				border-radius: 0 0 10px 10px;
			}
			.title {
				// font-family: Archivo, sans-serif;
				font-size: 16px;
				font-weight: 700;
				line-height: 17px;
				letter-spacing: 0;
				text-align: left;
				color: $text-color;
				margin-top: $margin-title-up;
			}
		}
		.wrapp-filters {
			width: calc(100% - 350px);
			display: flex;
			flex-wrap: wrap;
			// align-items: center;
			justify-content: flex-end;

			.dropdown {
				padding: 0 10px;
				border-radius: $input-radius;
				// color: $text-color;
				// font-size: 14px;
				outline: none;
				// background-color: transparent;
				// text-indent: 10px;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 150px;
				align-items: center;
				// text-overflow: ellipsis;
				margin-right: 10px;
			}
			.date {
				padding: 0 10px 0 2px;
				margin-top: 10px;
			}
		}
		.wrapp-action {
			display: flex;
			// align-items: center;
			.btn {
				margin-top: 7px;
			}
		}
	}
}

.action {
	max-width: 40px;
	margin-left: auto;
	display: flex;
	align-items: center;
	height: 40px !important;
	position: relative;
	padding-right: 22px;

	span {
		cursor: pointer;
	}

	img {
		cursor: pointer;
		margin-left: 18px;
	}

	.show-reports-data {
		width: 149px;
		min-height: 04px;
		box-sizing: border-box;
		padding: 12px 15px 0;
		position: absolute;
		z-index: 1;
		top: 0;
		right: 1px;
		border-radius: 20px;
		background: $background-color;
		border: 1px solid #dfdfe8;
		// background: #e44e75;

		font-size: 15px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.03em;
		text-align: left;

		& > .close-btn {
			margin: 0 0 auto auto;
			display: flex;
			align-self: center;
			padding: 3px;
			justify-content: flex-end;
			position: absolute;
			top: 5px;
			right: 5px;

			.close-icon {
				$size: 13px;

				height: $size;
				width: $size;
				cursor: pointer;

				path {
					fill: #aeadad;
				}

				&:hover {
					path {
						fill: #ffb4c3;
						transition: all 0.3s ease;
					}
				}
			}
		}

		img {
			cursor: pointer;
			margin-left: 18px;
			position: absolute;
		}

		.wrapp-info {
			margin-bottom: 15px;
		}
		.wrapp-info:hover {
			color: $app-accent-color1;
			cursor: pointer;
		}
	}
}
</style>
<style lang="scss">
.company-listing {
	.main {
		.info-row {
			box-sizing: border-box;
			max-width: 100%;
			padding: 25px 10px;
		}
		td {
			font-size: 13px;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0;
			text-align: left;
			padding-left: 5px;
			padding-right: 5px;
			cursor: pointer;
		}
		.bold {
			font-weight: 700;
		}
		.btn-s {
			box-sizing: border-box;
			min-width: 92px !important;
			min-height: 29px !important;
			border-radius: 84px;
			padding: 7px 20px;
			font-size: 11px;
			line-height: 15px;
			letter-spacing: 0;
			text-align: center;
		}
	}
	.search-wrapper {
		margin-right: 10px;
		margin-top: 10px;
		width: 20%;
		min-width: 150px !important;
		input {
			min-width: 150px !important;
			max-width: 100% !important;
			width: 100% !important;
		}
	}
	input[type="date"],
	.dropdown {
		margin-top: 10px;
	}
}
</style>
