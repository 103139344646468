import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { i18n } from "./i18n";
import { createPinia } from "pinia";
import InlineSvg from "vue-inline-svg";
import Toast from "vue-toastification";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { ProCalendar } from "vue-pro-calendar";
import { Field, Form, ErrorMessage } from "vee-validate";

import "vue-toastification/dist/index.css";

const pinia = createPinia();
const app = createApp(App);
const app2 = createApp(App);
app.use(pinia);
app.use(router);
app.use(ProCalendar);
app.use(i18n);

app.component("InlineSvg", InlineSvg);
app.component("FieldComp", Field);
app.component("FormComp", Form);
app.component("ErrorMessage", ErrorMessage);

const options = {
	position: "bottom-right",
	timeout: 5000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: false,
	icon: false,
	rtl: false,
	toastClassName: "my-custom-toast-class",
};
app.use(Toast, options);
if (import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY) {
	app.use(VueReCaptcha, {
		siteKey: import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY,
		loaderOptions: {
			autoHideBadge: true,
		},
	});
}

app.mount("#app");
app2.mount("#app2");
