<script setup lang="ts">
import api from "@/api";
import { useToastStore } from "@/store/toast";
import { useTokenStore } from "@/store/token";

definePage({
	name: "FeedbackPage",
});

// interface IType {
// 	fdb_name: string;
// 	fdb_email: string;
// 	fdb_message: string;
// 	fdb_file?: null | File;
// 	google_token: string;
// }
interface IForm {
	name: string;
	type: string;
	label: string;
	value: string;
	error: boolean;
}

const toastStore = useToastStore();
const tokenStore = useTokenStore();
const disableFields = ref(false);
const files = ref<any[]>([]);

const form2 = reactive<IForm[]>([
	{
		name: "fdb_name",
		type: "text",
		label: "firstLastName",
		value: "",
		error: false,
	},
	{
		name: "fdb_email",
		type: "text",
		label: "email",
		value: "",
		error: false,
	},
	// {
	// 	name: "phone_number",
	// 	type: "text",
	// 	label: "phoneNum",
	// 	value: "",
	// 	error: false,
	// },
	{
		name: "fdb_message",
		type: "textarea",
		label: "message",
		value: "",
		error: false,
	},
]);

// const token = ref("");
// const resetToken = ref(false);
const policy = ref(false);

function checkData() {
	let ifErr = false;
	const reg = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

	for (const element of form2) {
		if (element.value.trim().length <= 0) {
			element.error = true;
			ifErr = true;
		}
		if (element.name === "email" && !reg.test(element.value)) {
			element.error = true;
			ifErr = true;
			toastStore.openToastError("Email is not valid.");
			return;
		}
	}

	if (ifErr) {
		toastStore.openToastError("All fields are required.");
		return;
	}
	if (!policy.value) {
		toastStore.openToastError("You must agree to our Privacy Policy.");
		return;
	}
	void sendData();
}

async function sendData() {
	// const params: IType = {
	// 	fdb_name: "",
	// 	fdb_email: "",
	// 	// phone_number: "",
	// 	fdb_message: "",
	// 	fdb_file: null,
	// 	google_token: token.value,
	// };

	let fd = new FormData();
	for (const element of form2) {
		// params[element.name] = element.value;
		fd.append(element.name, element.value);
	}
	fd.append("fdb_file", files.value[0]);
	// resetToken.value = !resetToken.value;
	try {
		const res = await api.addFeedback(fd);
		console.log(res);
		clearForm();
		toastStore.openToastSuccess("You have successfully sent your feedback.");
	} catch {
		toastStore.openToastError("Error");
	}
}

function clearForm() {
	for (const element of form2) {
		if (
			tokenStore.userData &&
			(element.name === "fdb_name" || element.name === "fdb_email")
		) {
			continue;
		}
		element.value = "";
		element.error = false;
	}
	policy.value = false;
	files.value = [];
}
function attachFile(e: any) {
	// console.log(e.files[0].size / 1024); // size of the file in bytes, with this converting to kilobytes);
	// files.value.push(e.files[0]);
	if (e.files[0].size <= 800_000) files.value = [e.files[0]];
	else toastStore.openToastError("The maximum file size is 800KB(kilobytes).");
}
function deleteFile(e: number) {
	console.log(files.value);
	files.value.splice(e, 1);
}

onMounted(() => {
	if (tokenStore.userData) {
		disableFields.value = true;
		for (const element of form2) {
			if (element.name === "fdb_name") {
				element.value = tokenStore.userData?.usr_name;
			}
			if (element.name === "fdb_email") {
				element.value = tokenStore.userData?.usr_email;
			}
		}
	}
});
</script>

<template lang="pug">
.feedback-page
	.wrappTxt
		.title {{ $t("app.feedback.title") }}
		.txtB {{ $t("app.contact.fillTxt") }}
		.wrapp-info
			.group
				.icon
					img(src="@/assets/image/iconsCV/email.svg")
				.info
					.label {{ $t("app.contact.email") }}
					.info-data info@imjob.rs
			.group
				.icon
					img(src="@/assets/image/iconsCV/phone.svg")
				.info
					.label {{ $t("app.contact.phoneNum") }}
					.info-data +381 11 4235 496
					.info-data +381 69 3040 595
			.group
				.icon
					img(src="@/assets/image/iconsCV/locationFill.svg")
				.info
					.label {{ $t("app.contact.location") }}
					.info-data No.11L Teodora Drajzera St.11040 Belgrade, Serbia
	.wrapp-form
		.wrapp-title
			.line
			.title {{ $t("app.feedback.sendFeedback") }}
		.input-group
			.group(v-for="(obj, index) in form2", :key="index")
				label {{ $t("app.contact." + obj.label) }}
				.wrapp-textarea(v-if="obj.type === 'textarea'")
					textarea.textarea(
						v-model="obj.value",
						:class="{ error: obj.error }",
						@keyup="obj.error = false"
					)
					.wrapp-file
						file-picker(:multiple="false", @change="attachFile($event)")
							template(#button)
								img.attach(
									src="@/assets/image/attach.svg",
									alt="",
									:title="$t('app.feedback.addFile')",
									style="cursor: pointer; width: 15px"
								)
						.msg-file(v-for="(file, index2) in files", :key="index2")
							label(:title="file?.name") {{ file.name }}
							.close-btn(
								data-name="close-action",
								:title="$t('app.feedback.deleteFile')",
								@click="deleteFile(index2)"
							)
								svg.close-icon(
									width="16",
									height="16",
									viewBox="0 0 16 16",
									fill="none",
									xmlns="http://www.w3.org/2000/svg"
								)
									path(
										fill-rule="evenodd",
										clip-rule="evenodd",
										d="M8.00009 9.41431L13.6709 15.0851L15.0851 13.6709L9.41431 8.00009L15.0851 2.32925L13.6709 0.915039L8.00009 6.58588L2.32925 0.915039L0.915039 2.32925L6.58588 8.00009L0.915039 13.6709L2.32925 15.0851L8.00009 9.41431Z",
										fill="#575758"
									)

				input(
					v-else,
					v-model="obj.value",
					:type="obj.type",
					:class="{ error: obj.error }",
					:disabled="(obj.name === 'fdb_name' || obj.name === 'fdb_email') && disableFields",
					@keyup="obj.error = false"
				)
			.g-check.modern-cbox
				input#policy(v-model="policy", type="checkbox")
				label.wrapp-label(for="policy")
					span.dark {{ $t("app.contact.checkBoxMsg") }}
					br
					span.blue {{ $t("app.contact.privacyPolicy") }}
			//- turn-stile(v-model="token", :resetToken="resetToken")
			button(@click="checkData")
				span {{ $t("app.contact.send") }}
</template>

<style lang="scss" scoped>
@import "@/assets/css/pageStyle/contactFeedback";
</style>
